<template>
  <div class="container-fluid page">
    <page-header :title="$t('infoscreens.last_zlags', { count: latest_number_of_zlags }).toUpperCase()" :show-sidebar="showSidebar()" :logo="logo" />
    <div class="row">
      <div class="col-8 zlagfeed">
        <table class="zlagfeed-table">
          <tbody>
            <tr v-for="(zlag, index) in zlags" :key="index">
              <td><img :src="getAvatarUrl(zlag.user_avatar)" width="85" height="85" /></td>
              <td class="pt-3 pb-3">
                <span class="type-display-5">{{ zlag.user_name }} {{ zlag.user_lastname }}</span>
                <span class="type-display-6"> {{ $t('infoscreens.climbed') }} </span>
                <span class="type-display-5">{{ zlag.zlaggable_name }} ({{ zlag.zlaggable_difficulty }})</span>
                <template v-if="zlag.zlags > 1">
                  {{ $t('infoscreens.and') }} <span class="type-display-5">{{ $t('infoscreens.x_more', { count: zlag.zlags - 1 }) }}</span></template
                >
                <br />
                <span class="date mr-3">
                  <img v-if="zlag.type === 'f'" src="@scg/../images/zlagfeed/flash.png" alt="" />
                  <img v-if="zlag.type === 'go'" src="@scg/../images/zlagfeed/go.png" alt="" />
                  <img v-if="zlag.type === 'os'" src="@scg/../images/zlagfeed/onsight.png" alt="" />
                  <img v-if="zlag.type === 'rp'" src="@scg/../images/zlagfeed/redpoint.png" alt="" />
                  <img v-if="zlag.type === 'tr'" src="@scg/../images/zlagfeed/toprope.png" alt="" />
                  {{ getFormattedDate(zlag.date) }}
                </span>
                <span class="location">{{ zlag.sector_name }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-4 zlagfeed-stats">
        <h2 class="type-label">{{ $t('infoscreens.average_grade').toUpperCase() }}</h2>
        <p class="type-display-1">{{ average_grade }}</p>
        <template v-if="meters_climbed !== null">
          <h2 class="type-label">{{ $t('infoscreens.meters_climbed').toUpperCase() }}</h2>
          <p class="type-display-1">{{ meters_climbed }}</p>
        </template>
        <canvas id="pie_chart"> </canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import PageHeader from '../components/page_header.vue'

export default {
  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'reset'],

  data: function () {
    return {
      latest_number_of_zlags: 50,
      zlags: [],
      total_duration: 45,
      average_grade: '',
      meters_climbed: 0,
      ascent_styles: [],
      interval: {},
    }
  },

  methods: {
    startPage: function () {
      this.getZlagsStats()
      this.getZlagfeed()
    },

    finishedPage: function () {
      clearInterval(this.interval)
      $('.zlagfeed-table tbody').animate(
        {
          scrollTop: 0,
        },
        1
      )
      this.$parent.$emit('finishedPage', this.total_duration)
    },

    getZlagfeed: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/zlags/' + this.latest_number_of_zlags)
        .then(response => {
          if (response.data) {
            this.zlags = response.data
            $('.zlagfeed-table tbody').animate({ scrollTop: 107 * this.zlags.length }, this.total_duration * 1000, this.finishedPage)
          }
        })
        .catch(error => {
          console.error('VL: error loading zlagfeed', error)
        })
    },

    getZlagsStats: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/zlags_stats/' + this.latest_number_of_zlags)
        .then(response => {
          if (response.data) {
            this.average_grade = response.data.average_grade
            this.meters_climbed = response.data.meters_climbed
            this.ascent_styles = response.data.ascent_styles
            let ctxt = $('#pie_chart').get(0).getContext('2d')
            var myChart = new Chart(ctxt, {
              type: 'doughnut',
              data: {
                datasets: [
                  {
                    data: [
                      this.ascent_styles.os || 0,
                      this.ascent_styles.f || 0,
                      this.ascent_styles.rp || 0,
                      this.ascent_styles.tr || 0,
                      this.ascent_styles.go || 0,
                    ],
                    backgroundColor: [
                      'rgba(54, 172, 156, 1.0)',
                      'rgba(249, 167, 43, 1.0)',
                      'rgba(218, 17, 89, 1.0)',
                      'rgba(68, 79, 157, 1.0)',
                      'rgba(101, 98, 99, 1.0)',
                    ],
                    datalabels: {
                      display: false,
                    },
                  },
                ],
                labels: ['Onsight', 'Flash', 'Redpoint', 'Go', 'Toprope'],
              },
              options: {
                responsive: true,
                legend: {
                  position: 'left',
                },
              },
            })
          }
        })
        .catch(error => {
          console.error('VL: error loading zlag stats', error)
        })
    },

    showSidebar: function () {
      return this.infoscreen.sidebar === 'show'
    },

    getAvatarUrl: function (avatar) {
      if (avatar && avatar !== '') {
        return 'https://d1ffqbcmevre4q.cloudfront.net/' + avatar
      } else {
        return 'https://d1ffqbcmevre4q.cloudfront.net/avatar_default_female_1.jpg'
      }
    },

    getFormattedDate: function (string) {
      let d = new Date(string)
      return d.getDate() + ' ' + this.$i18n.t('month_names')[d.getMonth()] + ' ' + d.getFullYear()
    },
  },

  components: {
    'page-header': PageHeader,
  },
}
</script>

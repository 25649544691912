<template>
  <div class="opening-hours">
    <h3 class="type-label">{{ $t('infoscreens.hours_of_operation').toUpperCase() }}</h3>
    <table class="opening-hours-table">
      <tr v-for="day_index in day_indexes" :key="day_index" :class="[is_today(day_index) ? 'today' : '']">
        <td class="day pr-4 type-display-4 mb-3">{{ $t('day_names')[day_index] }}</td>
        <td v-if="spans_for_day(day_index).length > 0">
          <div v-for="span in spans_for_day(day_index)" :key="span.id">{{ span.opening_time }}-{{ span.closing_time }}</div>
        </td>
        <td v-else>{{ $t('settings.opening_hours.specific.closed') }}</td>
        <td v-if="span_updated_for_day(day_index)" class="warning"><div>!</div></td>
      </tr>
    </table>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  created: function () {
    this.get_opening_hours()
  },

  props: ['gym'],

  data: function () {
    return {
      opening_hours: [],
      day_indexes: [1, 2, 3, 4, 5, 6, 0],
    }
  },

  computed: {
    ms_in_day() {
      return 24 * 60 * 60 * 1000
    },
  },

  methods: {
    get_opening_hours() {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/opening_hours')
        .then(response => {
          if (response.data) {
            this.opening_hours = response.data
          }
        })
        .catch(error => {
          console.error('VL: error loading opening hours', error)
        })
    },

    spans_for_day(day_index) {
      if (!this.opening_hours || !this.opening_hours.recurring || !this.opening_hours.specific) return []
      const spans = this.specific_for_day_index(day_index)
      if (spans && spans.length > 0) {
        if (spans[0].open) return spans
        return []
      }
      return this.opening_hours.recurring.filter(span => span.day == day_index)
    },

    span_updated_for_day(day_index) {
      return this.spans_for_day(day_index).some(span => new Date(span.updated_at) > new Date() - 14 * this.ms_in_day)
    },

    is_today(day_number) {
      return day_number == new Date().getDay()
    },

    get_date_from_day_index(day_index) {
      const now = new Date()
      return new Date(now - (now.getDay() - day_index) * this.ms_in_day)
    },

    specific_for_day_index(day_index) {
      const date = this.get_date_from_day_index(day_index)
      return this.opening_hours.specific.filter(span => this.dates_are_same_day(new Date(span.date), date))
    },

    dates_are_same_day(first, second) {
      return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()
    },
  },
}
</script>
